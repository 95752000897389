import React from 'react';
import MainTextComponent from '../MainTextComponent/MainTextComponent';
import {
  EmptyContentWrapper,
  EmptyDescriptionText,
  EmptyMainContent,
  EmptyTitleText,
} from './style';
import Image from 'next/image';
import eBidsIcon from '../../../public/images/emptyEbids.png';
import { Title } from '../Title';

interface StaticImageData {
  src: string;
  height: number;
  width: number;
  blurDataURL?: string;
}

interface IEmptyContentComponent {
  reverseHeight?: string | null;
  headerTitle?: string;
  headerSubTitle?: string;
  image?: StaticImageData | any;
  title?: string;
  description?: string;
}

const EmptyContentComponent = ({
  reverseHeight,
  headerTitle,
  headerSubTitle,
  title,
  description,
}: IEmptyContentComponent) => {
  return (
    <EmptyContentWrapper reverseHeight={reverseHeight}>
      {headerTitle ? (
        <MainTextComponent text={headerTitle!} marginBottom={16} marginTop={32} />
      ) : null}
      {headerSubTitle ? (
        <Title fontSize={20} text={headerSubTitle!} />
      ) : null}

      <EmptyMainContent>
        <Image src={eBidsIcon} alt="empty" />
        <EmptyTitleText>{title!}</EmptyTitleText>
        <EmptyDescriptionText>{description!}</EmptyDescriptionText>
      </EmptyMainContent>
    </EmptyContentWrapper>
  );
};

export default EmptyContentComponent;
